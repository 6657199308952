
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap");

*,
*:after,
*:before { 
   box-sizing: border-box;
}


body {
   font-family:  "Inter", sans-serif;
   line-height: 1.5;
   overflow-y: hidden;
}

input,
button,
select,
textarea {
   font: inherit;
}

img {
   display: block;
   max-width: 100%;
}



.window {
   display: grid;
   grid-template-columns: auto auto auto;
   grid-template-rows: 100vh;
}

.window-header {
   background-color: #05043e;
}

.navigation {
   padding: 0.75rem;
   display: flex;
   flex-direction: column;
   height: 100%;
   justify-content: space-between;
}

.navigation-top,
.navigation-bottom {
   display: flex;
   flex-direction: column;
}

.navigation-item {
   display: inline-flex;
   align-items: center;
   justify-content: center;
   width: 3rem;
   height: 3rem;
   border-radius: 6px;
   color: #fff;
   transition: 0.15s ease;
   position: relative;
   & + * {
      margin-top: 0.5rem;
   }

   &:hover,
   &.active {
      background-color: mix(#fff, #05043e, 15%);
   }

   .avatar {
      max-width: 1.75rem;
      max-height: 1.75rem;
   }

   &:hover {
	.navigation-item-title {
		transform: scale(1);
		opacity: 1;
	}
   }

   &--notification {
	   &::after {
		   position: absolute;
		   content: "";
		   display: block;
		   width: 8px;
		   height: 8px;
		   background-color: #3f5efb;
		   border-radius: 50%;
		   top: 8px;
		   right: 8px;
	   }
   }
}

.navigation-item-title {
	justify-self: flex-end;
	position: absolute;
	transform: scale(0);
	transform-origin: 0% 50%;
	left: calc(100% + .75rem);
	opacity: 0;
	transition: .15s ease;
	width: auto;
	z-index: 300;
	background-color: mix(#fff, #05043e, 15%);
	display: block;
	white-space: nowrap;
	padding: .5em 1em;
	border-radius: 6px;
	box-shadow: 0 4px 8px 0 rgba(#000, .2);
	&:before {
		position: absolute;
		content: "";
		display: block;
		width: 10px;
		height: 10px;
		background-color: mix(#fff, #05043e, 15%);
		transform: translateY(-50%) rotate(45deg);
		left: -5px;
		top: 50%; 
		box-shadow: 0 4px 8px 0 rgba(#000, .2);
	}
}

.window-main-header {
   border-bottom: 1px solid #ddd;
   background-color: #fff;
   display: flex;
   justify-content: space-between;
	flex-wrap: wrap;
   align-items: center;
   padding: 0.75rem 0.75rem 0.75rem 2rem;
}

.window-main {
   
}

.window-main-body {
   display: grid;
   grid-template-columns: 4fr 1fr;
   grid-template-rows: calc(100vh - 62px);
   
}

.breadcrumbs {
   display: flex;
   flex-wrap: wrap;
   margin-bottom:0px;
}

.breadcrumbs-item {
   margin-right: 0.375rem;
   display: inline-flex;
   align-items: center;
   &:not(:last-child):after {
      margin-left: 0.375rem;
      content: "";
      display: inline-flex;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 6L15 12L9 18' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      background-size: 1rem;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      width: 1.5rem;
      height: 1em;
   }
   a {
      color: #454545;
      display: inline-flex;
      align-items: center;
      text-decoration: none;
      svg {
         width: 1rem;
         margin-right: 0.375rem;
         color: #8015cb;
      }
   }
}

.editor {
   padding-top: 3rem;
   padding-bottom: 5rem;
   padding-left: 5vw;
   padding-right: 5vw;
   height: 100%;
   overflow-y: auto;
   background-color: #efefef;
   &::-webkit-scrollbar {
      width: 15px;
      background-color: #efefef;
   }

   &::-webkit-scrollbar-thumb {
      width: 5px;
      border-radius: 99em;
      background-color: #cdcdcd;
      border: 5px solid #efefef;
   }
}
.editor-input-group {
   display: flex;
   flex-direction: column;
   & + * {
      margin-top: 1.5rem;
   }
}

.editor-input-label {
   font-weight: 600;
}

.editor-textarea {
   margin-top: -1px;
   border: 1px solid #ddd;
   padding: 0.75em;
}

.editor-toolbar {
   background-color: #fff;
   border: 1px solid #ddd;
   padding: 0.5rem;
   display: flex;
   flex-wrap: wrap;
   top: calc(-3rem - 1px);
   position: sticky;
   z-index: 200;
   align-items: center;
}

.editor-toolbar-item {
   background-color: transparent;
   border: 0;
   display: inline-flex;
   align-items: center;
   justify-content: center;

   border-radius: 6px;
   transition: 0.15s ease;

   &.icon {
      width: 2rem;
      height: 2rem;
   }

   &.dropdown {
      min-width: 150px;
      justify-content: space-between;
      height: 2rem;
   }

   svg {
      width: 1.25rem;
      height: 1.25rem;
   }
   &:hover {
      background-color: #ededed;
   }
}

.separator {
   height: 1rem;
   width: 2px;
   background-color: #DDD;
   display: block;
   margin-left: .625rem;
   margin-right: .625rem;
}

.editor-textarea {
   background-color: #fff;
   min-height: 50vh;
   max-width: 100%;
   padding: 0;
   overflow: hidden;
}

.editor-textarea-editable {
   padding: 3em 0;
   padding-left: 5vw;
   padding-right: 5vw;
   line-height: 1.65;
   & > * + * {
      margin-top: 1em;
   }

   h1 {
      font-size: 2rem;
      font-weight: 500;
      line-height: 1.25;
   }

   h2 {
      font-weight: 500;
      line-height: 1.375;
      font-size: 1.5rem;
   }

   ul {
      list-style: square;
      margin-left: 40px;
      li {
         & + li {
            margin-top: .5em;
         }
      }
   }

   em {
      font-style: italic;
   }

   strong {
      font-weight: 600;
   }

   img {
      max-width: 50%;
      float: left;
      margin-right: 1em;
      margin-top: 1em;
      margin-bottom: 1em;
      transition: 0.15s ease;
      cursor: pointer;
      &:hover {
         box-shadow: 0 0 0 4px #3f5efb;
      }
   }

   a {
      color: #3f5efb;
      text-decoration: none;
      box-shadow: 0 2px 0 0 currentcolor;
      font-weight: 600;
   }

   .leading {
      font-size: 1.25rem;
      font-weight: 500;
      color: #565656;
   }
}

.window-panel {
   background-color: #19194a;
   padding: 2rem;
   position: relative;
   overflow-y: auto;
   

   &::-webkit-scrollbar {
      width: 15px;
      background-color: #19194a;
   }
   
   &::-webkit-scrollbar-corner {
      background-color: #19194a;
   }

   &::-webkit-scrollbar-thumb {
      width: 5px;
      background-color: mix(#FFF,#19194a, 25%);
      border: 5px solid #19194a;
   }

   &:after {
      content: "";
      width: calc(100% + 4rem);
      height: 50px;
      margin-left: -2rem;
      bottom: -2rem;
      background-image: linear-gradient(to top, #19194a 0%, transparent 100%);
      display: block;
      position: sticky;
      z-index: 100;
   }
}

.window-main-body-right {
   background-color: #fff;
   border-left: 1px solid #ddd;
   overflow-y: auto;
   padding-bottom: 3rem;
   &::-webkit-scrollbar {
	width: 15px;
	background-color: #fff;
 }

 &::-webkit-scrollbar-thumb {
	width: 5px;
	border-radius: 99em;
	background-color: #cdcdcd;
	border: 5px solid #fff;
 }
}

.settings-section {
   padding: 1.5rem;
   & + * {
      border-top: 1px solid #ddd;
   }
}

.section-title {
   font-weight: 600;
   letter-spacing: 0.05em;
   color: #565656;
   text-transform: uppercase;
   font-size: 0.75rem;
   margin-bottom: 1rem;
   .window-panel & {
      color: mix(#FFF,#19194a, 50%);
   }
}

.search {
   margin-bottom: 1.5rem;
}

.search-input {
   border: 0;
   background-color: transparent;
   color: #fff;
   border-bottom: 1px solid mix(#FFF,#19194a, 25%);
   padding: 0.5em 0;
   padding-left: 1.75rem;
   font-size: 0.875rem;
   background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' display='block' id='Search'%3E%3Cpath d='M21 21l-4.486-4.494M19 10.5a8.5 8.5 0 1 1-17 0 8.5 8.5 0 0 1 17 0z'/%3E%3C/svg%3E");
   background-repeat: no-repeat;
   background-position: 0 50%;
   &::placeholder {
      color: mix(#FFF,#19194a, 50%);;
   }
   &:focus {
      border-color: #fff;
   }
}

.tree {
   display: flex;
   flex-wrap: wrap;
   flex-direction: column;
   padding-left: 1rem;
   position: relative;
   &:before {
      display: block;
      content: "";
      position: absolute;
      width: 2px;
      height: calc(100% - 2em);
      background-color: mix(#FFF,#19194a, 25%);;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
   }

   &--sub {
      &:after {
         position: absolute;
         content: "";
         display: block;
         height: 2px;
         width: 1rem;
         background-color: mix(#FFF,#19194a, 25%);;
         top: 1.25em;
         left: -1rem;
      }
   }
}

.tree-branch {
   &--sub {
   }
}

.tree-branch-action {
   display: flex;
   flex-wrap: wrap;
   align-items: center;
   button {
      opacity: 0;
      transition: 0.15s ease;
      background-color: mix(#FFF,#19194a, 25%);;
      color: #fff;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: 0.75em;
      width: 1rem;
      height: 1rem;
      border: 0;
      border-radius: 4px;
      cursor: pointer;
      margin-left: 0.5rem;
      &:hover {
         background-color: mix(#FFF,#19194a, 75%);
         color: #19194a;
      }
   }

   &:hover {
      button {
         opacity: 1;
      }
   }
}

.tree-branch-link {
   color: inherit;
   // font-size: 0.875rem;
   color: mix(#FFF,#19194a, 75%);
   text-decoration: none;
   position: relative;
   display: block;
   padding-top: 0.5em;
   padding-bottom: 0.5em;
   transition: 0.15s ease;
   &:before {
      position: absolute;
      content: "";
      display: block;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background-color: #19194a;
      border: 2px solid mix(#FFF,#19194a, 25%);;
      top: 1em;
      left: calc(-1rem - 4px);
      z-index: 1;
      transition: 0.15s ease;
      transform-origin: 50% 50%;
   }

   
   &.active,
   &:hover {
	   color: #fff;
	   &:before {
		   border-color: #fff;
		   transform: scale(1.25);
		}
	}
	&--404 {
	   &:before {
		  border-radius: 0;
		  transform: rotate(45deg);
	   }
 
	   &:hover:before {
		 transform: scale(1.25) rotate(45deg);
	   }
	}
	
   &.active {
      font-weight: 500;
      &:before {
         background-color: #fff;
      }
   }
}

.tree-branch-nolink {
   color: inherit;
   // font-size: 0.875rem;
   color: mix(#FFF,#19194a, 75%);
   text-decoration: none;
   font-weight: bold;
   position: relative;
   display: block;
   padding-top: 0.5em;
   padding-bottom: 0.5em;
   transition: 0.15s ease;

   
   &.active,
   &:hover {
	   color: #fff;
	   &:before {
		   border-color: #fff;
		   transform: scale(1.25);
		}
	}
	&--404 {
	   &:before {
		  border-radius: 0;
		  transform: rotate(45deg);
	   }
 
	   &:hover:before {
		 transform: scale(1.25) rotate(45deg);
	   }
	}
	
   &.active {
      font-weight: 500;
      &:before {
         background-color: #fff;
      }
   }
}

.publish-actions {
   display: flex;
   align-items: center;
}

.publish-info {
   font-size: 0.75rem;
   color: #666;
   line-height: 1.25;
   margin-right: 1.25rem;
   text-align: center;
}

.button {
   display: inline-flex;
   align-items: center;
   border: 0;
   padding: 0.375em 0.75em;
   border-radius: 4px;
   background-color: transparent;
   cursor: pointer;
   border-bottom: 1px solid;
   margin-right: 0.5rem;
   transition: .25s ease;
   font-weight: 500;
   svg {
      margin-right: 0.375em;
      width: 1.25em;
      height: 1.25em;
   }

   &--save {
      background-color: #e0e4fa;
      color: #2f47be;
      &:hover {
         color: #e0e4fa;
         background-color: #2f47be;
      }
   }

   &--schedule {
      background-color: #ece0fa;
      color: #722fbe;
      &:hover {
         color: #ece0fa;
         background-color: #722fbe;
      }
   }

   &--publish {
      background-color: #8ae2b6;
      color: #22744b;
      &:hover {
         color: #8ae2b6;
         background-color: #22744b;
      }
   }

   &--delete {
      background-color: #fdc8bf;
      color: #bd1717;
      &:hover {
         color: #fdc8bf;
         background-color: #bd1717;
      }
   }
}

.input-label {
   font-size: 0.875rem;
   font-weight: 500;
   margin-bottom: 0.25rem;
}

.input-field {
   padding: 0.375em 0.5em;
   border: 1px solid #ccc;
   font-size: 0.875rem;
   transition: 0.15s ease;
   &:hover {
      border-color: #3f5efb;
   }

   &--textarea {
      min-height: calc(6em * 1.5);
      max-width: 100%;
      min-width: 100%;
      resize: none;
      &::-webkit-scrollbar {
         width: 15px;
         background-color: #fff;
      }

      &::-webkit-scrollbar-thumb {
         width: 5px;
         border-radius: 99em;
         background-color: #cdcdcd;
         border: 5px solid #fff;
      }
   }
}

.input-url {
   color: #3f5efb;
   font-size: 0.875rem;
}

.input-image {
   padding: 0;
   border: 1px solid #ddd;
   background-color: #fff;
   border-radius: 0 0 4px 4px;
   border-bottom-width: 2px;
   cursor: pointer;
   transition: 0.15s ease;
   &:hover {
      border-color: #3f5efb;
   }
}

.input-image-wrapper {
   width: calc(100% + 2px);
   margin-top: -1px;
   margin-left: -1px;
   overflow: hidden;
		display: block;
}

.input-image-meta {
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding: 0.5em;
}

.input-image-meta-title {
   font-weight: 500;
   font-size: 0.875rem;
}

.input-image-meta-action {
   color: #3f5efb;
   font-weight: 700;
   letter-spacing: 0.05em;
   font-size: 0.75rem;
   text-transform: uppercase;
}

.input-checkbox {
   display: flex;
   align-items: center;
   cursor: pointer;
   & + * {
      margin-top: 0.75rem;
   }
}

.input-checkbox-box {
   clip: rect(0 0 0 0);
   clip-path: inset(100%);
   height: 1px;
   overflow: hidden;
   position: absolute;
   white-space: nowrap;
   width: 1px;

   &:checked + .input-checkbox-toggle {
      background-color: #3f5efb;
      border-color: #3f5efb;
      &:after {
         background-color: #fff;
         transform: translateX(100%);
      }
   }
}

.input-checkbox-toggle {
   position: relative;
   display: inline-block;
   width: 32px;
   height: 20px;
   border-radius: 99em;
   border: 1px solid #999;
   margin-right: 0.375rem;
   &:after {
      content: "";
      position: absolute;
      top: 3px;
      left: 3px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: #999;
   }
}

:focus {
   outline: 0;
}

pre {
   background-color: white;
   border: 1px  #999;
   padding: 10px;
}